import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getAddressOfOrder, getEventById, getTicketTypeById, getTicketTypeInfosByTicketTypeId } from "ticketino-api-client";

import Header from './components/Header'
import Footer from "./components/Footer";

const Ticket = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [buyerInfo, setBuyerInfo] = useState({
        firstName: "",
        name: "",
        email: "",
        street: "",
        code1: "", //house number
        mobile: "",
        city: "",
        postCode: "",
        countryId: 176,
        email: "",
        confirmemail: "",
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});
    const [order, setOrder] = useState({});
    const [tickets, setTickets] = useState([]);
    const orderAddress = JSON.parse(sessionStorage.getItem("FassadenOrderAddress"));

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]); //gets called when an order is started

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const removePromotionCode = async (promotion) => {
        try {
            const response = axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotion}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);

            const bookedTickets = updatedOrder.tickets;

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {
                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId) ?? info[0];

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            setOrder(updatedOrder);
            setTickets(updatedTickets);

            const address = await getAddressOfOrder(orderId);
            setBuyerInfo({ ...buyerInfo, ...address });
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const onInputChange = (e, ticketId) => {

        tickets.forEach(t => {
            if (t.id === ticketId) {
                t[e.target.name] = e.target.value;
            }
        });

        setTickets([...tickets]);
    };

    const addDataToTickets = () => {

        console.log(tickets);

        //update ticket address
        tickets.forEach((t, key, tickets) => {
            let ticketBody = {
                firstname: t.firstname,
                name: t.lastname,
                company: t.company,
                mobile: t.mobile,
                street: t.street,
                option1: t.option1, 
                postCode: t.postCode,
                city: t.city,
                email: t.email
            };

            axios
                .put(`${baseUrl}/Ticket/${t.id}/Address`, ticketBody)
                .then(() => {
                    console.log("we hit here");

                    if (Object.is(tickets.length - 1, key)) {
                        /* redirect to checkout page on last interation */
                        navigate(`/${language}/checkout`)
                    }
                })
                .catch((error) => console.log(error.response.data));
        });
    }

    // validating input fields
    let validatorTicketInputs = 0;

    tickets.forEach(t => {
        if (!(
            t.firstname && /^.{1,70}$/.test(t.firstname) &&
            t.lastname && /^.{1,70}$/.test(t.lastname) &&
            t.company && /^.{1,70}$/.test(t.company) &&
            t.mobile && /^.{1,70}$/.test(t.mobile) &&
            t.street && /^.{1,70}$/.test(t.street) &&
            t.option1 && /^.{1,70}$/.test(t.option1) && //house number
            t.postCode && /^.{1,70}$/.test(t.postCode) &&
            t.city && /^.{1,70}$/.test(t.city) &&
            t.email && /^\S+@\S+\.\S+$/.test(t.email))) {
            validatorTicketInputs += 1;
        }
    });

    const onBackClick = async () => {
        const promotioncode = sessionStorage.getItem("addedPromotion");

        if (promotioncode?.length > 0) {
            await removePromotionCode(promotioncode);
        }

        navigate(-1)
    }

    const onClickFillUserData = (ticketId) => {

        tickets.forEach(t => {

            if (t.id === ticketId) {
                t.firstname = orderAddress.firstName;
                t.lastname = orderAddress.name;
                t.email = orderAddress.email;
                t.mobile = orderAddress.mobile;
                t.company = orderAddress.company;
                t.street = orderAddress.street;
                t.option1 = orderAddress.code1;
                t.postCode = orderAddress.postCode;
                t.city = orderAddress.city;
            }
        });

        setTickets([...tickets]);
    };

    const mapTicketsUserData = () => {

        let ticketsSorted = [];

        if (tickets && tickets.length > 0) {
            ticketsSorted = [...tickets].sort((a, b) => a.id - b.id);
        }

        return (
            tickets &&
            tickets.length > 0 &&
            ticketsSorted.map((tt, index) => (
                    <div key={tt.id} className="mb-5">
                        <div className="row mt-2">
                            <div className="col-md-12">
                            <h5 className="text-uppercase">Ticket: {tt.info?.name}</h5>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12">
                            <small className="button" onClick={() => onClickFillUserData(tt.id)}>
                                {resources?.TicketInfoGetFromBuyer}</small>
                            </div>
                        </div>

                        <div className="mt-4 mb-3 row">
                            <label className="col-md-3 col-12 mb-2" htmlFor="firstName">
                                {resources?.BuyerFirstname}
                            </label>
                            <div className="col-sm-9 col-md-9 col-12">
                                <input
                                    type="text"
                                    name="firstname"
                                    className="form-control"
                                    placeholder={resources?.BuyerFirstname}
                                    value={tt.firstname}
                                    id="firstname"
                                    onChange={(e) => onInputChange(e, tt.id)}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-12 mb-2" htmlFor="name">
                                {resources?.BuyerLastname}
                            </label>
                            <div className="col-sm-9 col-md-9 col-12">
                                <input
                                    type="text"
                                    name="lastname"
                                    className="form-control"
                                    placeholder={resources?.BuyerLastname}
                                    value={tt.lastname}
                                    id="lastname"
                                    onChange={(e) => onInputChange(e, tt.id)}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-12 mb-2" htmlFor="company">
                                {resources?.BuyerCompany}
                            </label>
                            <div className="col-sm-9 col-md-9 col-12">
                                <input
                                    type="text"
                                    name="company"
                                    className="form-control"
                                    placeholder={resources?.BuyerCompany}
                                    value={tt.company}
                                    id="company"
                                    onChange={(e) => onInputChange(e, tt.id)}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-12 mb-2" htmlFor="email">
                                {resources?.BuyerEmail}
                            </label>
                            <div className="col-sm-9 col-md-9 col-12">
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control"
                                    placeholder={resources?.BuyerEmail}
                                    value={tt.email}
                                    id="email"
                                    onChange={(e) => onInputChange(e, tt.id)}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-12 mb-2" htmlFor="street">
                                {resources?.BuyerNumber}
                            </label>
                            <div className="col-sm-9 col-md-9 col-12">
                                <input
                                    type="text"
                                    name="mobile"
                                    className="form-control"
                                    placeholder={resources?.BuyerNumber}
                                    value={tt.mobile}
                                    id="mobile"
                                    onChange={(e) => onInputChange(e, tt.id)}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-12 mb-2" htmlFor="street">
                                {resources?.BuyerStreet} / {resources?.BuyerHouseNumber}
                            </label>
                            <div className="col-md-9 col-12 row pe-0">
                                <div className="col-md-8 col-8 pe-0">
                                    <input
                                        type="text"
                                        name="street"
                                        className="form-control"
                                        placeholder={resources?.BuyerStreet}
                                        value={tt.street}
                                        id="street"
                                        onChange={(e) => onInputChange(e, tt.id)}
                                    />
                                </div>
                                <div className="col-md-4 col-4 pe-0">
                                    <input
                                        type="text"
                                        name="option1"
                                        className="form-control"
                                        placeholder={resources?.BuyerHouseNumber}
                                        value={tt.option1}
                                        id="option1"
                                        onChange={(e) => onInputChange(e, tt.id)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-12 mb-2" htmlFor="postCode">
                                {resources?.BuyerPostcode} / {resources?.BuyerCity}
                            </label>
                            <div className="col-md-9 col-12 row pe-0">
                                <div className="col-md-4 col-4 pe-0">
                                    <input
                                        type="text"
                                        name="postCode"
                                        className="form-control"
                                        placeholder={resources?.BuyerPostcode}
                                        value={tt.postCode}
                                        id="postCode"
                                        onChange={(e) => onInputChange(e, tt.id)}
                                    />
                                </div>
                                <div className="col-md-8 col-8 pe-0">
                                    <input
                                        type="text"
                                        name="city"
                                        className="form-control"
                                        placeholder={resources?.BuyerCity}
                                        value={tt.city}
                                        id="city"
                                        onChange={(e) => onInputChange(e, tt.id)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            ))
        );
    }

    return (
        <div>
            <Header />
            <div className="container mt-4 page-container-smaller">
                <p className="fs-5 fw-bold mb-2">{resources?.TicketInfoTitle}</p>
                <hr></hr>

                {mapTicketsUserData()}

                <div className="row mt-4 mb-4">
                    <div className=" col-6 text-start">
                        <button className="button" onClick={onBackClick}>
                            {resources?.Back}
                        </button>
                    </div>
                    <div className="col-6 text-end">
                        <button className="button" disabled={validatorTicketInputs > 0} onClick={addDataToTickets}>{resources.Buy}</button>
                    </div>
                </div>
            </div>
            <Footer language={language} />
        </div>
    )
}

export default Ticket